@import "~@/styles/variables/variables.scss";








































































































































































































































































































































































@import '../runLayout.scss';
.directive-content {
	flex-direction: row;
	padding: 0 12px;
	.left {
		width: 300px;
		height: 100%;
		padding: 20px 8px 8px 0;
		border-right: 1px solid #f1f1f1;
		& + .right {
			padding-left: 20px;
		}
	}
	.right {
		flex: 1;
		width: 0;
		padding-left: 20px;
		display: flex;
		height: 100%;
		flex-direction: column;
		.filter {
			display: flex;
			flex-wrap: wrap;
			padding-top: 20px;
			.search {
				margin-right: 20px;
				margin-bottom: 14px;
			}
		}
		.table {
			flex: 1;
			height: 0;
		}
	}
}
